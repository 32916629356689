import { useState, useEffect, useContext } from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { useParams } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate, useSearchParams } from "react-router-dom";
import Select from 'react-select';

import prevArrow from '../Assets/Images/prev-arrow.svg';
import nextArrow from '../Assets/Images/next-arrow.svg';
import CreatableSelect from 'react-select/creatable';

import '../App.scss';
import AdditionalQuestionContext from '../Store/AdditionalQuestionContext';

const jobTitleValues = [
  {value: "Other", label: "Other"},
  {value: "Administrative Pastor", label: "Administrative Pastor"},
  {value: "Lead Pastor", label: "Lead Pastor"},
  {value: "Campus Pastor", label: "Campus Pastor"},
  {value: "Children's Pastor", label: "Children's Pastor"},
  {value: "College Pastor", label: "College Pastor"},
  {value: "Community Pastor", label: "Community Pastor"},
  {value: "Connections Pastor", label: "Connections Pastor"},
  {value: "Creative Arts Pastor", label: "Creative Arts Pastor"},
  {value: "Discipleship Pastor", label: "Discipleship Pastor"},
  {value: "Executive Pastor", label: "Executive Pastor"},
  {value: "Family Pastor", label: "Family Pastor"},
  {value: "High School Pastor", label: "High School Pastor"},
  {value: "Leadership Development Pastor", label: "Leadership Development Pastor"},
  {value: "Middle School Pastor", label: "Middle School Pastor"},
  {value: "Missions Pastor", label: "Missions Pastor"},
  {value: "Outreach Pastor", label: "Outreach Pastor"},
  {value: "Personal Assistant to Lead Pastor", label: "Personal Assistant to Lead Pastor"},
  {value: "Senior Pastor", label: "Senior Pastor"},
  {value: "Small Group Pastor", label: "Small Group Pastor"},
  {value: "Stewardship Pastor", label: "Stewardship Pastor"},
  {value: "Student Pastor", label: "Student Pastor"},
  {value: "Teacher Pastor", label: "Teacher Pastor"},
  {value: "Worship Pastor", label: "Worship Pastor"},
  {value: "Administrative Assistant", label: "Administrative Assistant"},  
  {value: "AV Director", label: "AV Director"},
  {value: "Bookkeeper", label: "Bookkeeper"},
  {value: "Chief Financial Officer", label: "Chief Financial Officer"},
  {value: "Children's Ministry Coordinator", label: "Children's Ministry Coordinator"},
  {value: "Communications Coordinator", label: "Communications Coordinator"},
  {value: "Communications Director", label: "Communications Director"},
  {value: "Communications Project Manager", label: "Communications Project Manager"},
  {value: "Content Writer", label: "Content Writer"},
  {value: "Digital Media Analyst", label: "Digital Media Analyst"},
  {value: "Events Coordinator", label: "Events Coordinator"},
  {value: "Facility Manager", label: "Facility Manager"},
  {value: "Graphic Designer", label: "Graphic Designer"},
  {value: "Groups Director", label: "Groups Director"},
  {value: "Guest Services Director", label: "Guest Services Director"},
  {value: "Human Resources Coordinator", label: "Human Resources Coordinator"},
  {value: "IT Director", label: "IT Director"},
  {value: "Mission and Outreach Coordinator", label: "Mission and Outreach Coordinator"},
  {value: "Office Manager", label: "Office Manager"},
  {value: "Online Community Manager", label: "Online Community Manager"},
  {value: "Pastoral Care Minister", label: "Pastoral Care Minister"},
  {value: "Preschool Director", label: "Preschool Director"},
  {value: "Production Coordinator", label: "Production Coordinator"},
  {value: "Social Media Manager", label: "Social Media Manager"},
  {value: "Video Producer", label: "Video Producer"},
  {value: "Volunteer Coordinator", label: "Volunteer Coordinator"},
  {value: "Web Developer", label: "Web Developer"},
  {value: "Worship Leader", label: "Worship Leader"},
];


const chmsValues = [
  { value: "ACS Technologies", label: "ACS Technologies" },
  { value: "Anedot", label: "Anedot" },
  { value: "Aplos Church", label: "Aplos Church" },
  { value: "Attendance Now", label: "Attendance Now" },
  { value: "Aware3", label: "Aware3" },
  { value: "Bitrix24", label: "Bitrix24" },
  { value: "Blackbaud Church Management", label: "Blackbaud Church Management" },
  { value: "Breeze", label: "Breeze" },
  { value: "CCIS Church Management Software", label: "CCIS Church Management Software" },
  { value: "CDM+", label: "CDM+" },
  { value: "CharityTracker", label: "CharityTracker" },
  { value: "Chaverware", label: "Chaverware" },
  { value: "ChMeetings", label: "ChMeetings" },
  { value: "Church Base", label: "Church Base" },
  { value: "Church Center", label: "Church Center" },
  { value: "Church Community Builder (CCB)", label: "Church Community Builder (CCB)" },
  { value: "Church Hero", label: "Church Hero" },
  { value: "Church MemberLink", label: "Church MemberLink" },
  { value: "Church Metrics", label: "Church Metrics" },
  { value: "Church Office Online", label: "Church Office Online" },
  { value: "Church Services", label: "Church Services" },
  { value: "Church Social", label: "Church Social" },
  { value: "Church Windows", label: "Church Windows" },
  { value: "ChurchDesk", label: "ChurchDesk" },
  { value: "ChurchSuite", label: "ChurchSuite" },
  { value: "ChurchTeams", label: "ChurchTeams" },
  { value: "ChurchThemes", label: "ChurchThemes" },
  { value: "ChurchTools", label: "ChurchTools" },
  { value: "ChurchTrac", label: "ChurchTrac" },
  { value: "Clearstream", label: "Clearstream" },
  { value: "Clover Sites", label: "Clover Sites" },
  { value: "Donarius", label: "Donarius" },
  { value: "Duplie", label: "Duplie" },
  { value: "eDisciples", label: "eDisciples" },
  { value: "Ekklesia 360", label: "Ekklesia 360" },
  { value: "Elexio Church Software", label: "Elexio Church Software" },
  { value: "Elvanto", label: "Elvanto" },
  { value: "eSPACE", label: "eSPACE" },
  { value: "Excellerate", label: "Excellerate" },
  { value: "EZTithe", label: "EZTithe" },
  { value: "Faith Teams", label: "Faith Teams" },
  { value: "Faithconnector", label: "Faithconnector" },
  { value: "Faithful Steward", label: "Faithful Steward" },
  { value: "FellowshipOne", label: "FellowshipOne" },
  { value: "FlockBase", label: "FlockBase" },
  { value: "Folknote", label: "Folknote" },
  { value: "Gabriel", label: "Gabriel" },
  { value: "IconCMO", label: "IconCMO" },
  { value: "Iglesia HOY", label: "Iglesia HOY" },
  { value: "infoodle", label: "infoodle" },
  { value: "KidCheck", label: "KidCheck" },
  { value: "Logos II", label: "Logos II" },
  { value: "Ministry Platform (ACS)", label: "Ministry Platform (ACS)" },
  { value: "Ministry Tracker", label: "Ministry Tracker" },
  { value: "Neon CRM", label: "Neon CRM" },
  { value: "Nucleus", label: "Nucleus" },
  { value: "One Church", label: "One Church" },
  { value: "Parish Data Systems (ACS)", label: "Parish Data Systems (ACS)" },
  { value: "ParishSOFT", label: "ParishSOFT" },
  { value: "Pastoral Care", label: "Pastoral Care" },
  { value: "PastorsLine", label: "PastorsLine" },
  { value: "PDS Church Office", label: "PDS Church Office" },
  { value: "Planning Center", label: "Planning Center" },
  { value: "PowerChurch Plus", label: "PowerChurch Plus" },
  { value: "Pushpay", label: "Pushpay" },
  { value: "Realm (ACS)", label: "Realm (ACS)" },
  { value: "Rock RMS", label: "Rock RMS" },
  { value: "Salesforce.org Nonprofit Cloud", label: "Salesforce.org Nonprofit Cloud" },
  { value: "Servant Keeper", label: "Servant Keeper" },
  { value: "Sharefaith", label: "Sharefaith" },
  { value: "Shelby Systems", label: "Shelby Systems" },
  { value: "SimpleChurch CRM", label: "SimpleChurch CRM" },
  { value: "SmartChurch", label: "SmartChurch" },
  { value: "Subsplash", label: "Subsplash" },
  { value: "Text In Church", label: "Text In Church" },
  { value: "Tithe.ly", label: "Tithe.ly" },
  { value: "ToucanTech", label: "ToucanTech" },
  { value: "TouchPoint", label: "TouchPoint" },
  { value: "UCare", label: "UCare" },
  { value: "Vanco Faith", label: "Vanco Faith" },
  { value: "Web Church Connect", label: "Web Church Connect" },
  { value: "Wild Apricot", label: "Wild Apricot" },
  { value: "eCatholic", label: "eCatholic" },
  { value: "Other", label: "Other" },
]

const Details = (props) => {

  const addCtx                        = useContext(AdditionalQuestionContext);
  let navigate                        = useNavigate();
  const { id }                        = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const [isSaving, setIsSaving]       = useState(false);
  const [isLoading, setIsLoading]     = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isMember, setIsMember]       = useState(true);

  const [firstName, setFirstName]     = useState("");
  const [fullName, setFullName]       = useState("");
  const [lastName, setLastName]       = useState("");
  const [email, setEmail]             = useState("");
  const [attendance, setAttendence]   = useState("");
  const [jobTitle, setJobTitle]       = useState("");
  const [chms, setChmsTitle]          = useState("");
  const [selectedJobValue, setSelectedJobValue]         = useState();
  const [selectedChmsValue, setSelectedChmsValue]         = useState();
  const [jobTitleMember, setJobTitleMember]       = useState("");
  const [churchName, setChurchName]   = useState("");
  const [schoolName, setSchoolName]   = useState("");
  const [zipCode, setZipCode]         = useState("");
  const [firstNameError, setFirstNameError]       = useState(false);
  const [lastNameError, setLastNameError]         = useState(false);
  const [emailError, setEmailError]               = useState(false);
  const [jobTitleError, setJobTitleError]         = useState(false);
  const [churchNameError, setChurchNameError]     = useState(false);
  const [schoolNameError, setSchoolNameError]     = useState(false);
  const [zipCodeError, setZipCodeError]           = useState(false);
  const [attendenceError, setAttendenceError]     = useState(false);
  const [chmsError, setChmsError]                 = useState(false);
  const [fullNameError, setFullNameError]         = useState(false);
  useEffect(() => {
    if(searchParams.get("member") != null) {
      getUserDetails();
    } else {
      setIsMember(false);
    }
    // document.body.style.backgroundColor = "#EEEEEE";
  }, []);

  const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

  // useEffect(() => {
  //   if (firstName  !== addCtx.formDetails.firstName)  addCtx.addFormDetails((prev) => ({ ...prev, firstName  }));
  //   if (lastName   !== addCtx.formDetails.lastName)   addCtx.addFormDetails((prev) => ({ ...prev, lastName   }));
  //   if (email      !== addCtx.formDetails.email)      addCtx.addFormDetails((prev) => ({ ...prev, email      }));
  //   if (jobTitle   !== addCtx.formDetails.jobTitle)   addCtx.addFormDetails((prev) => ({ ...prev, jobTitle   }));
  //   if (churchName !== addCtx.formDetails.churchName) addCtx.addFormDetails((prev) => ({ ...prev, churchName }));
  //   if (zipCode    !== addCtx.formDetails.zipCode)    addCtx.addFormDetails((prev) => ({ ...prev, zipCode    }));
  // }, [firstName, lastName, email, jobTitle, churchName, zipCode]);

  useEffect(() => {
    if (fullName  !== addCtx.formDetails.fullName)  addCtx.addFormDetails((prev) => ({ ...prev, fullName  }));
    if (firstName    !== addCtx.formDetails.chms)    addCtx.addFormDetails((prev) => ({ ...prev, firstName}));
    if (lastName    !== addCtx.formDetails.chms)    addCtx.addFormDetails((prev) => ({ ...prev, lastName}));
    if (email      !== addCtx.formDetails.email)      addCtx.addFormDetails((prev) => ({ ...prev, email      }));
    if (jobTitle   !== addCtx.formDetails.jobTitle)   addCtx.addFormDetails((prev) => ({ ...prev, jobTitle   }));
    if (churchName !== addCtx.formDetails.churchName) addCtx.addFormDetails((prev) => ({ ...prev, churchName }));
    if (attendance   !== addCtx.formDetails.attendance)   addCtx.addFormDetails((prev) => ({ ...prev, attendance   }));
    if (chms    !== addCtx.formDetails.chms)    addCtx.addFormDetails((prev) => ({ ...prev, chms}));

  }, [fullName, email, jobTitle, churchName, attendance, chms, firstName, lastName]);
 
  // useEffect(() => {
  //   let formDetails = addCtx.formDetails;

  //   if (formDetails?.firstName?.length)  setFirstName(formDetails.firstName);
  //   if (formDetails?.lastName?.length)   setLastName(formDetails.lastName);
  //   if (formDetails?.email?.length)      setEmail(formDetails.email);
  //   if (formDetails?.churchName?.length) setChurchName(formDetails.churchName);
  //   if (formDetails?.zipCode?.length)    setZipCode(formDetails.zipCode);

  //   if (formDetails?.jobTitle?.length) {
  //     let selected = jobTitleValues?.find(job => job.value === formDetails?.jobTitle);
  //     // console.log("typeof selected", typeof selected, selected);
  //     setSelectedValue(selected);
  //     setJobTitle(selected?.value);
  //   }
  // }, [addCtx.formDetails])

  useEffect(() => {
    let formDetails = addCtx.formDetails;

    if (formDetails?.fullName?.length)  setFullName(formDetails.fullName);
    if (formDetails?.email?.length)      setEmail(formDetails.email);
    if (formDetails?.churchName?.length) setChurchName(formDetails.churchName);
    if (formDetails?.attendance?.length)   setAttendence(formDetails.attendance);
    if (formDetails?.firstName?.length)   setFirstName(formDetails.firstName);
    if (formDetails?.lastName?.length)   setLastName(formDetails.lastName);
    // if (formDetails?.zipCode?.length)    setZipCode(formDetails.zipCode);

    let selected = jobTitleValues?.find(job => job.value === formDetails?.jobTitle);
    console.log("typeof selected", typeof selected, selected);
    if (formDetails?.jobTitle?.length) {
      let selected = jobTitleValues?.find(job => job.value === formDetails?.jobTitle);
      setSelectedJobValue(selected);
      selected?.value && setJobTitle(selected?.value);
    }
    if (formDetails?.chms?.length) {
      let selected = chmsValues?.find(chms => chms.value === formDetails?.chms);
      // console.log("typeof selected", typeof selected, selected);
      setSelectedChmsValue(selected);
      selected?.value && setChmsTitle(selected?.value);
    }
  }, [addCtx.formDetails]);


  console.log("addCtx.formDetails", addCtx.formDetails);
  // console.log("typeof jobTitle", typeof jobTitle, jobTitle);

  const getUserDetails = () => {
    setIsLoading(true);
  
    fetch(process.env.REACT_APP_API_URI + '/v1/user/' + searchParams.get("member"), {
      method: 'GET',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' }
    }).then(response => {
      return response.json();
    }).then(data => {
      if(data.status == "success") {
        setIsMember(true);
        setFirstName(data.firstName);
        setLastName(data.lastName);
        setFullName(data.fullName);
        setEmail(data.email);
        // setJobTitle("none");
        // setJobTitle("");
        setIsLoading(false);
      } else {
        // setOpenSnackbar(true);
      }
    });
  }

  const updateFirstName = (event) => {
    setFirstName(event.target.value);
    checkFormValid();
  }

  const updateFullName = (event) => {
    setFullName(event.target.value);
    checkFormValid();
  }
  const updateLastName = (event) => {
    setLastName(event.target.value);
    checkFormValid();
  }

  const updateEmail = (event) => {
    setEmail(event.target.value);
    checkFormValid();
  }

  // const updateJobTitle = (event) => {
  const updateJobTitle = (selectedOption) => {
    // console.log(event.target.value);
    console.log(selectedOption.value);
    setSelectedJobValue(selectedOption);
    setJobTitle(selectedOption.value);
    checkFormValid();
  }

  const updateChms = (selectedOption) => {
    // console.log(event.target.value);
    console.log(selectedOption.value);
    setSelectedChmsValue(selectedOption);
    setChmsTitle(selectedOption.value);
    checkFormValid();
  }
  
  const updateSchoolName = (event) => {
    setSchoolName(event.target.value);
    checkFormValid();
  }
  
  const updateChurchName = (event) => {
    setChurchName(event.target.value);
    checkFormValid();
  }

  const updateZipCode = (event) => {
    setZipCode(event.target.value);
    checkFormValid();
  }

  const updateAttendence = (event) => {
    setAttendence(event.target.value);
    checkFormValid();
  }

  // const checkFormValid = () => {
  //   if(isMember) {
  //     console.log(firstName);
  //     console.log(jobTitle);
  //     // console.log(jobTitleMember);
  //     if(firstName && lastName) {
  //       console.log("We get here");
  //       setIsFormValid(true);
  //     } else {
  //       setIsFormValid(false);
  //     }
  //   } else {
  //     // if(firstName != "" && lastName != "" && email != "" && emailRegex.test(email) && jobTitle != "" && churchName != "" && zipCode.length > 3 && zipCode.length < 13) {
  //     if(firstName && lastName && email && emailRegex.test(email) && jobTitle && churchName && zipCode.length > 3 && zipCode.length < 13) {
  //       setIsFormValid(true);
  //     } else {
  //       setIsFormValid(false);
  //     }
  //   }
  // }

  const checkFormValid = () => {
    if(isMember) {
      console.log(fullName);
      console.log(jobTitle);
      // console.log(jobTitleMember);
      if(firstName && lastName && jobTitle ) {
        console.log("We get here");
        setIsFormValid(true);
      } else {
        setIsFormValid(false);
      }
    } else {
      // if(firstName != "" && lastName != "" && email != "" && emailRegex.test(email) && jobTitle != "" && churchName != "" && zipCode.length > 3 && zipCode.length < 13) {
      if(fullName && churchName && email && emailRegex.test(email) && attendance && jobTitle && chms) {
        setIsFormValid(true);
      } else {
        setIsFormValid(false);
      }
    }
  }
  const showErrors = () => {
    if(!firstName) {
      setFirstNameError(true);
      setTimeout(() => { console.log(firstNameError); }, 2000);
    }
    if(!lastName) {
      setLastNameError(true);
    }
    if(!emailRegex.test(email)) {
      setEmailError(true);
    }
    // if(jobTitle == "none" || !jobTitle) {
    if(!jobTitle) {
      setJobTitleError(true);
    }
    // if(schoolName == "") {
    //   setSchoolNameError(true);
    // }
    if(!churchName) {
      setChurchNameError(true);
    }
    if(zipCode.length < 4) {
      setZipCodeError(true);
    }

    if(!attendance) {
      setAttendenceError(true);
    }

    if(!chms) {
      setChmsError(true);
    }

    if(!fullName) {
      setFullNameError(true);
    }
  }

  const submitToApi = () => {
    
    let apiCall         = '/v1/assessment/' + id + '/leader';
    if(isMember) {
      apiCall           = '/v1/assessment/' + id + '/member/' + searchParams.get("member");
    }

    let jsonBody        = JSON.stringify({
      details: {
        "firstName": firstName,
        "lastName": lastName,
        // "zipCode": zipCode,
        "fullName": fullName,
        "email": email,
        "jobTitle": jobTitle,
        "churchName": churchName,
        "avgWeeklyAttendance": attendance,
        "chms": chms,
      },
      formVersion: 2
    });

    fetch(process.env.REACT_APP_API_URI + apiCall, {
      method: 'PUT',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: jsonBody
    }).then(response => {
      return response.json();
    }).then(data => {
      if(data.status == "success") {
        localStorage.removeItem("assessmentAnswers");
        localStorage.removeItem("additionalAnswers");
        navigate("/results/" + id, { replace: true });
      } else {
        // setOpenSnackbar(true);
      }
    });

  }

  const handleSubmit = () => {
    setIsSaving(true);
    console.log("Runnign");


    // if(isMember && firstName && lastName && jobTitle) {
    if(isMember && fullName && jobTitle) {
      submitToApi();
    // } else if(!isMember && firstName != "" && lastName != "" && emailRegex.test(email) && jobTitle != "none" && churchName != "" && zipCode.length > 3 && zipCode.length < 13) {
    // } else if(!isMember && firstName && lastName && emailRegex.test(email) && jobTitle && churchName && zipCode.length > 3 && zipCode.length < 13) {
    } else if(!isMember && fullName && emailRegex.test(email) && jobTitle && churchName && chms && attendance) {
      submitToApi();
    } else {
      showErrors();
      setIsSaving(false);
    }

  }

  const showButton = () => {
    if(isSaving) {
      return <div className="button-progress"><CircularProgress className="loading-icon" style={{ color: '#202945' }} size={23} /></div>
    }
    return "GET YOUR RESULTS";
  }

  const showHintText = (field) => {
    if(field == "firstName") {
      if(firstNameError) {
        return <div className="error-text">Please confirm your first name.</div>
      }
    }
    if(field == "lastName") {
      if(lastNameError) {
        return <div className="error-text">Please confirm your last name.</div>
      }
    }
    if(field == "email") {
      if(emailError) {
        return <div className="error-text">Please enter a valid email address.</div>
      }
    }
    if(field == "jobTitle") {
      if(jobTitleError) {
        return <div className="error-text">Please provide a job title.</div>
      }
    }
    if(field == "orgName") {
      if(churchNameError) {
        return <div className="error-text">Please confirm your church name.</div>
      }
    }
    // if(field == "churchName") {
    //   if(churchNameError) {
    //     return <div className="error-text">Please confirm your church name.</div>
    //   }
    // }
    if(field == "zipCode") {
      if(zipCodeError) {
        return <div className="error-text">Please confirm your ZIP code.</div>
      }
    }

    if(field == "chms") {
      if(chmsError) {
        return <div className="error-text">Please confirm your ChMS.</div>
      }
    }

    if(field == "attendance") {
      if(attendenceError) {
        return <div className="error-text">Please confirm your attendence.</div>
      }
    }

    if(field == "fullname") {
      if(fullNameError) {
        return <div className="error-text">Please confirm your full name.</div>
      }
    }
  }

  const removeHintText = (field, e) => {

    // change active input field title color 
    if(e.target.closest(".form-detail")) {
      document.body.querySelectorAll(".input-title").forEach(elm => elm.style.color = "#4A4A4A")
      e.target.closest(".form-detail").querySelector(".input-title").style.color = "#009DF1";
    }

    if(field == "firstName") {
      setFirstNameError(false);
    }
    if(field == "lastName") {
      setLastNameError(false);
    }
    if(field == "fullName") {
      setFullNameError(false);
    }
    if(field == "email") {
      setEmailError(false);
    }
    if(field == "jobTitle") {
      setJobTitleError(false);
    }
    if(field == "orgName") {
      setChurchNameError(false);
    }
    // if(field == "churchName") {
    //   setChurchNameError(false);
    // }
    if(field == "zipCode") {
      setZipCodeError(false);
    }
    if(field == "chms") {
      setChmsError(false);
    }
    if(field == "attendance") {
      setAttendenceError(false);
    }
  }

  const prevQuestion = () => {
    // navigate("/assessment-additional/" + id, { replace: true });
    if(searchParams.get("leader")) {
      navigate('/assessment/' + id + '/review?' + searchParams, { replace: true });
    } else {
      navigate('/assessment/' + id + "/review?" + searchParams, { replace: true });
    }
  };

  if (isLoading) {
		return <div className="container details loading"><CircularProgress style={{ color: '#F7D54D' }} size={60} /></div>
	}

  return (
    <div className="container details">
      
      <div className="details-container">
        
        <h1> You're almost done!</h1>
        <div style={{ height: '10px' }}></div>
        <p>Before we get to your report, please confirm your details so that we can email you a copy.</p>

        <div className="form">

        {!isMember &&  <div className="form-detail">
            <p className="input-title">Full Name<span className="required-field">*</span></p>
            <TextField hiddenLabel placeholder="Simon Peter" value={fullName} onFocus={(e) => removeHintText("fullName", e)} onChange={(e) => {updateFullName(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
            {showHintText("fullname")}
          </div>
        }
          {
            !isMember ? (
              <div className="form-detail school-name">
                <p className="input-title">Church Name<span className="required-field">*</span></p>
                <TextField hiddenLabel placeholder="Petra Church" value={churchName} onFocus={(e) => removeHintText("orgName", e)} onChange={(e) => {updateChurchName(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
                {showHintText("orgName")}
              </div>
            ) : null
          }

          {isMember && <div className="form-detail">
            <p className="input-title">First Name<span className="required-field">*</span></p>
            <TextField hiddenLabel placeholder="Simon" value={firstName} onFocus={(e) => removeHintText("firstName", e)}  onChange={(e) => {updateFirstName(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
            {showHintText("firstName")}
          </div> 
          }

          {isMember && <div className="form-detail">
            <p className="input-title">Last Name<span className="required-field">*</span></p>
            <TextField hiddenLabel placeholder="Peter" value={lastName} onFocus={(e) => removeHintText("lastName", e)}  onChange={(e) => {updateLastName(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
            {showHintText("lastName")}
          </div> 
          }

          <div className="form-detail">
            <p className="input-title">Email<span className="required-field">*</span></p>
            <TextField hiddenLabel placeholder="speter@petra.church" disabled={isMember} value={email} onFocus={(e) => removeHintText("email", e)} onChange={(e) => {updateEmail(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
            {showHintText("email")}
          </div>



         { !isMember &&<div className="form-detail">
            <p className="input-title">Average Weekly Attendance<span className="required-field">*</span></p>
            <TextField hiddenLabel placeholder="e.g. 1,000" value={attendance}  onFocus={(e) => removeHintText("attendance", e)} onChange={(e) => {updateAttendence(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
            {showHintText("attendance")}
          </div> 
          }

            <div className="form-detail">
            <p className="input-title">Job Title<span className="required-field">*</span></p>

            {/* <TextField hiddenLabel placeholder="Board Member" value={jobTitle} onFocus={(e) => removeHintText("jobTitle", e)} onChange={(e) => {updateJobTitle(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" /> */}

            {/* <TextField select hiddenLabel value={jobTitle} onFocus={(e) => removeHintText("jobTitle", e)} onChange={(e) => {updateJobTitle(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text">
              <MenuItem key={0} value="none" disabled>Please select an option below</MenuItem>
              {jobTitleValues.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.value}
                </MenuItem>
              ))}
            </TextField> */}

            {/* Added react-select instead of MUI TextField */}
            <Select classNamePrefix="react-select" className="form-details-text react-select" 
              options={jobTitleValues} 
              onFocus={(e) => removeHintText("jobTitle", e)}
              onChange={updateJobTitle}
              value={selectedJobValue}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#009DF133',
                  primary: '#009DF1',
                  neutral0: '#F4F4F4',
                  neutral50: '#B5B5B5', // placeholder text color
                },
              })}
              placeholder={"Start typing or select"} />
            {showHintText("jobTitle")}
          </div> 
          



          


          {/* {
            !isMember ? (
              <div className="form-detail">
                <p className="input-title">School name<span className="required-field">*</span></p>
                <TextField hiddenLabel placeholder="Rock Church" value={schoolName} onFocus={(e) => removeHintText("schoolName", e)} onChange={(e) => {updateSchoolName(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
                {showHintText("churchName")}
              </div>
            ) : null
          } */}
          
          {/* {
            !isMember ? (
              <div className="form-detail">
                <p className="input-title">ZIP code<span className="required-field">*</span></p>
                <TextField hiddenLabel 
                  type="text" 
                  placeholder="11618" 
                  className="form-details-text" 
                  value={zipCode} 
                  onFocus={(e) => removeHintText("zipCode", e)} 
                  onChange={(e) => {updateZipCode(e)}} 
                  fullWidth variant="standard" size="normal" 
                  InputProps={{ disableUnderline: true }} 
                  onInput = {(e) => {
                    return e.target.value = e.target.value?.toString().slice(0,12)
                  }}
                />
                {showHintText("zipCode")}
              </div>
            ) : null
          } */}
          {!isMember && <div className="form-detail">
            <p className="input-title">Current ChMS<span className="required-field">*</span></p>

            {/* Added react-select instead of MUI TextField */}
            {/* <Select classNamePrefix="react-select" className="form-details-text react-select" */}
            <Select classNamePrefix="react-select" className="form-details-text react-select" 
              options={chmsValues} 
              onFocus={(e) => removeHintText("chms", e)}
              onChange={updateChms}
              value={selectedChmsValue}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#009DF133',
                  primary: '#009DF1',
                  neutral0: '#F4F4F4',
                  neutral50: '#B5B5B5', // placeholder text color
                },
              })}
              placeholder={"Start typing or select"} />
            {showHintText("chms")}
          </div>}


        </div>
          <div className="details-buttons" style={{ margin: isMember ? "45px auto 5px" : "0 auto" }}>
            <div className="back-btn">
              <Button onClick={prevQuestion}>
                <img src={prevArrow} alt="previous button" />&nbsp;&nbsp;PREV
              </Button>
            </div>

            <div className="cta-btn">
              <Button onClick={handleSubmit} disabled={isSaving}>
                {showButton()}
              </Button>
            </div>
          </div>

      </div>
      
    </div>
  );
  
}
export default Details;