import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { Link, useParams } from "react-router-dom";
import Tabs from './Tabs';
import Slider from '@mui/material/Slider';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import Switch from '@mui/material/Switch';

import prevArrow from '../Assets/Images/prev-arrow.svg';
import nextArrow from '../Assets/Images/next-arrow.svg';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UrgencyScore from '../Components/UrgencyScore';
import useWindowDimensions from '../Hooks/useWindowDimensions';

import scheduledImage from "../Assets/Images/schedule-image.png";

import '../App.scss';

const Dashboard = (props) => {
  
	const { id }                        			= useParams();

	const [isLoading, setIsLoading]   				= useState(true);
	const [currentView, setCurrentView] 			= useState(0);

  const [toggleIndividual, setToggleIndividual]		= useState(0);
  const [toggleSection, setToggleSection]		= useState(1);
	const [toggleMore, setToggleMore] 				= useState(0);	

	const [members, setMembers]        				= useState([]);
	const [selected, setSelected] 						= useState(0);
	const [questions, setQuestions]   				= useState([]);
	const [sections, setSections]   					= useState([]);
	const [hurdles, setHurdles]        				= useState([]);
	const [enablers, setEnablers]     				= useState([]);
	const [tops, setTops]     								= useState([]);
	const [bottoms, setBottoms]     					= useState([]);
	
	const [score, setScore]             			= useState(0);
  const [subScore1, setSubScore1]     			= useState(0);
  const [subScore2, setSubScore2]     			= useState(0);
  const [subScore3, setSubScore3]     			= useState(0);
  const [subScore4, setSubScore4]     			= useState(0);
  const [subScore5, setSubScore5]     			= useState(0);
  const [subScore6, setSubScore6]     			= useState(0);
  const [subScore7, setSubScore7]     			= useState(0);
  const [subScore8, setSubScore8]     			= useState(0);
  const [urgencyVote1, setUrgencyVote1]			= useState(0);
  const [urgencyVote2, setUrgencyVote2]			= useState(0);
  const [urgencyVote3, setUrgencyVote3]			= useState(0);
  const [urgencyVote4, setUrgencyVote4]			= useState(0);
  const [urgencyVote5, setUrgencyVote5]			= useState(0);
  const [urgencyScore, setUrgencyScore]			= useState(0);
	const [memberSection1, setMemberSection1] = useState([]);
	const [memberSection2, setMemberSection2] = useState([]);
	const [memberSection3, setMemberSection3] = useState([]);
	const [memberSection4, setMemberSection4] = useState([]);
	const [subScores, setSubScores] 					= useState([0, 0, 0, 0]);

	const [memberScore, setMemberScore] 			= useState(0);
	const [memberName, setMemberName] 				= useState(0);
  const [memSubScore1, setMemSubScore1]			= useState(0);
  const [memSubScore2, setMemSubScore2]			= useState(0);
  const [memSubScore3, setMemSubScore3]			= useState(0);
  const [memSubScore4, setMemSubScore4]			= useState(0);
  const [memSubScore5, setMemSubScore5]			= useState(0);
  const [memSubScore6, setMemSubScore6]			= useState(0);
  const [memSubScore7, setMemSubScore7]			= useState(0);
  const [memSubScore8, setMemSubScore8]			= useState(0);
  const [memUrgencyScore, setMemUrgencyScore]	= useState(0);

	const { windowWidth } = useWindowDimensions();

	const scores = [subScore1, subScore2, subScore3, subScore4, subScore5, subScore6, subScore7, subScore8];
	const memScores = [memSubScore1, memSubScore2, memSubScore3, memSubScore4, memSubScore5, memSubScore6, memSubScore7, memSubScore8];
	
	let urgencyPercent 			= 60.0;
	if(window.innerWidth > 650) {
		urgencyPercent 				= 63.9;
	}

	useEffect(() => {
		// document.body.style.backgroundColor = "#007670";
    fetchResults(); 
  }, []);

	// to animate subScores on results page 
  useEffect(() => {
    const timer = setTimeout(() => {
      setSubScores([subScore1, subScore2, subScore3, subScore4]);
    }, 250);
    return () => {
      clearTimeout(timer);
    };
  }, [subScore1, subScore2, subScore3, subScore4])

  const fetchResults = () => {

    fetch(process.env.REACT_APP_API_URI + '/v1/assessment/dashboard/' + id, {
			method: 'GET',
			mode: 'cors',
			headers: { 'Content-Type': 'application/json' }
		}).then(response => {
			return response.json();
		}).then(data => {
			if(data.status == "success") {
				setScore(data.score);
				setSubScore1(data.subScore1);
				setSubScore2(data.subScore2);
				setSubScore3(data.subScore3);
				setSubScore4(data.subScore4);
				setUrgencyScore(data.urgencyScore);
				setMembers(data.assessmentInfo);
				setQuestions(data.questionInfo);
				setSections(data.sectionInfo);
				setHurdles(data.hurdles);
				setEnablers(data.enablers);
				
				// console.log(data.questionInfo);

				setSelected(data.assessmentInfo[0].id);
				setMemberScore(data.assessmentInfo[0].score);
				setMemberName(data.assessmentInfo[0].fullName);
				setMemSubScore1(data.assessmentInfo[0].subScore1);
				setMemSubScore2(data.assessmentInfo[0].subScore2);
				setMemSubScore3(data.assessmentInfo[0].subScore3);
				setMemSubScore4(data.assessmentInfo[0].subScore4);
				setMemUrgencyScore(data.assessmentInfo[0].addAnswer1);

				let urgencyVote1  = 0;
				let urgencyVote2  = 0;
				let urgencyVote3  = 0;
				let urgencyVote4  = 0;
				let urgencyVote5  = 0;

				let section1Questions 		= data.questionInfo.filter(function (obj) { return obj.sectionId == 1; });
				section1Questions.forEach((question) => {
					let answer 							= data.assessmentInfo[0].answers.find(function (obj) { return obj.question_id == question.id});
					question.answer 				= answer.answer;
				});
				setMemberSection1(section1Questions);
				
				let section2Questions 		= data.questionInfo.filter(function (obj) { return obj.sectionId == 2; });
				section2Questions.forEach((question) => {
					let answer 							= data.assessmentInfo[0].answers.find(function (obj) { return obj.question_id == question.id});
					question.answer 				= answer.answer;
				});
				setMemberSection2(section2Questions);

				let section3Questions 		= data.questionInfo.filter(function (obj) { return obj.sectionId == 3; });
				section3Questions.forEach((question) => {
					let answer 							= data.assessmentInfo[0].answers.find(function (obj) { return obj.question_id == question.id});
					question.answer 				= answer.answer;
				});
				setMemberSection3(section3Questions);
				
				let section4Questions 		= data.questionInfo.filter(function (obj) { return obj.sectionId == 4; });
				section4Questions.forEach((question) => {
					let answer 							= data.assessmentInfo[0].answers.find(function (obj) { return obj.question_id == question.id});
					question.answer 				= answer.answer;
				});
				setMemberSection4(section4Questions);
				
				let tops 					= data.questionTop.sort((a, b) => parseFloat(b.count) - parseFloat(a.count));
				let bottoms 			= data.questionBottom.sort((a, b) => parseFloat(b.count) - parseFloat(a.count));

				let urgVote1  		= 0;
				let urgVote2  		= 0;
				let urgVote3  		= 0;
				let urgVote4  		= 0;
				let urgVote5  		= 0;

				data.assessmentInfo.forEach((member) => {
					if(member.addAnswer1 == 1) urgVote1++; 
					else if(member.addAnswer1 == 2) urgVote2++;
					else if(member.addAnswer1 == 3) urgVote3++;
					else if(member.addAnswer1 == 4) urgVote4++;
					else if(member.addAnswer1 == 5) urgVote5++;
				})

				setUrgencyVote1(urgVote1);
				setUrgencyVote2(urgVote2);
				setUrgencyVote3(urgVote3);
				setUrgencyVote4(urgVote4);
				setUrgencyVote5(urgVote5);

				setTops(tops.slice(0, 3));
				setBottoms(bottoms.slice(0, 3));
				setIsLoading(false);

				// console.log(tops);
				// console.log(bottoms);
				// console.log(questions);
			} else {
				// setOpenSnackbar(true);
			}
		});
  }

	// there are 2 urgencyScore like bars for 2 questions for both, team and individual 
	const teamVotesQ1 			= [0, 0, 0, 0, 0];
	const teamVotesQ2 			= [0, 0, 0, 0, 0];
	const individualVotesQ1 = [0, 0, 0, 0, 0];
	const individualVotesQ2 = [0, 0, 0, 0, 0];

	const teamAnswers3 = [];
	const teamAnswers4 = [];
	const individualAnswer3 = [];
	const individualAnswer4 = [];

	members?.forEach((member) => {
		let question1 = member.answers?.find(a => a.question_id == 664);
		let question2 = member.answers?.find(a => a.question_id == 665);
		let question3 = member.answers?.find(a => a.question_id == 666);
		let question4 = member.answers?.find(a => a.question_id == 667);
		if (question1?.answer) teamVotesQ1[question1?.answer - 1] += 1;
		if (question2?.answer) teamVotesQ2[question2?.answer - 1] += 1;
		if (question3?.answer_txt) teamAnswers3.push(question3?.answer_txt);
		if (question4?.answer_txt) teamAnswers4.push(question4?.answer_txt);
	})
	console.log("teamAnswers3", teamAnswers3);
	console.log("teamAnswers4", teamAnswers4);

	const individualMember = members?.find(m => m.id == selected);
	let question1 = individualMember?.answers?.find(a => a.question_id == 664);
	let question2 = individualMember?.answers?.find(a => a.question_id == 665);
	let question3 = individualMember?.answers?.find(a => a.question_id == 666);
	let question4 = individualMember?.answers?.find(a => a.question_id == 667);
	if (question1?.answer) individualVotesQ1[question1?.answer - 1] += 1;
	if (question2?.answer) individualVotesQ2[question2?.answer - 1] += 1;
	if (question3?.answer_txt) individualAnswer3.push(question3?.answer_txt);
	if (question4?.answer_txt) individualAnswer4.push(question4?.answer_txt);
	
	console.log("teamVotesQ1", teamVotesQ1, "teamVotesQ2", teamVotesQ2);
	console.log("individualVotesQ1", individualVotesQ1, "individualVotesQ2", individualVotesQ2);

	const headerText1 = questions?.find(q => q.id == 664)?.question;
	const headerText2 = questions?.find(q => q.id == 665)?.question;
	const labels1 = questions?.find(q => q.id == 664)?.answers?.map(a => a?.desktop_text);
	const labels2 = questions?.find(q => q.id == 665)?.answers?.map(a => a?.desktop_text);

	const urgencyComponentPropsQ1Team = { urgencyScore, urgencyPercent, urgencyVote1, urgencyVote2, urgencyVote3, urgencyVote4, urgencyVote5, headerText: headerText1, labels: labels1, votes: teamVotesQ1, team: true };
	const urgencyComponentPropsQ2Team = { urgencyScore, urgencyPercent, urgencyVote1, urgencyVote2, urgencyVote3, urgencyVote4, urgencyVote5, headerText: headerText2, labels: labels2, votes: teamVotesQ2, team: true };
	const urgencyComponentPropsQ1Individual = { urgencyScore, urgencyPercent, urgencyVote1, urgencyVote2, urgencyVote3, urgencyVote4, urgencyVote5, headerText: headerText1, labels: labels1, votes: individualVotesQ1, team: false };
	const urgencyComponentPropsQ2Individual = { urgencyScore, urgencyPercent, urgencyVote1, urgencyVote2, urgencyVote3, urgencyVote4, urgencyVote5, headerText: headerText2, labels: labels2, votes: individualVotesQ2, team: false };



	const showTitleText = () => {
    if (score >= 0 && score <= 50) {
      // return <h1><span className="user-name">{memberName?.split(" ")?.[0]}</span>, your ChMS is Sub-optimal</h1>
      return <h1>Your ChMS seems suboptimal.</h1>
    } else if (score > 50 && score <= 80) {
      return <h1>Your ChMS seems adequate.</h1>
    } else if (score > 80) {
      return <h1>Your ChMS seems great.</h1>
    }
  };

  const showScoreText = (scoreVal) => {
    if (scoreVal >= 0 && scoreVal <= 50) {
      return <div className="result-text">
        <p 
					// style={{ margin: windowWidth > 650 ? "2px auto 30px" : "2px auto 15px" }}
					>Your ChMS seems fine but may not be the best fit for your church.</p>
        {/* <p>Invite your team to take this assessment to know how they feel about its effectiveness.</p> */}
      </div>
    } else if (scoreVal > 50 && scoreVal <= 80) {
      return <div className="result-text">
        <p 
					// style={{ margin: windowWidth > 650 ? "2px auto 30px" : "2px auto 15px" }}
					>Your ChMS seems to serve your church well, yet there may be a need for improvement.</p>
				{/* <p>Invite your team to take this assessment to know how they feel about its effectiveness.</p> */}
      </div>
    } else if (scoreVal > 80) {
      return <div className="result-text">
        <p 
					// style={{ margin: windowWidth > 650 ? "2px auto 30px" : "2px auto 15px" }}
					>Your ChMS seems pretty perfect!</p>
        {/* <p>Invite your team to take this assessment to know how they feel about its effectiveness.</p> */}
      </div>
    }
  };

	const toggleTeamIndividual = (tab) => {
		if(tab == 1) {
			setCurrentView(0);
			setToggleIndividual(1);
		} else {
			setCurrentView(1);
			setToggleIndividual(0);
			changeIndividualAssessment(members[0].id)
		}
	}

	const updateIndividualAssessment = (e) => {
		let memberId 			= e.target.value;
		changeIndividualAssessment(memberId);
	}

	const changeIndividualAssessment = (id) => {
		let member 				= members.find(function (obj) { return obj.id == id; });
		console.log(member);
		console.log(questions);
		let sectionIds 		= [];
		sections.forEach((section) => {
			sectionIds.push(section.id);
		});
		console.log(sectionIds);
		setSelected(member.id);
		setMemberScore(member.score);
		setMemberName(member.fullName);
		setMemSubScore1(member.subScore1);
		setMemSubScore2(member.subScore2);
		setMemSubScore3(member.subScore3);
		setMemSubScore4(member.subScore4);
		setMemUrgencyScore(member.addAnswer1);
		
		let section1Questions 		= questions.filter(function (obj) { return obj.sectionId == sectionIds[0]; });
		section1Questions.forEach((question) => {
			let answer 							= member.answers.find(function (obj) { return obj.question_id == question.id});
			question.answer 				= answer.answer;
		});

		setMemberSection1(section1Questions);
		
		let section2Questions 		= questions.filter(function (obj) { return obj.sectionId == sectionIds[1]; });
		section2Questions.forEach((question) => {
			let answer 							= member.answers.find(function (obj) { return obj.question_id == question.id});
			question.answer 				= answer.answer;
		});
		setMemberSection2(section2Questions);

		let section3Questions 		= questions.filter(function (obj) { return obj.sectionId == sectionIds[2]; });
		section3Questions.forEach((question) => {
			let answer 							= member.answers.find(function (obj) { return obj.question_id == question.id});
			question.answer 				= answer.answer;
		});
		setMemberSection3(section3Questions);
		
		let section4Questions 		= questions.filter(function (obj) { return obj.sectionId == sectionIds[3]; });
		section4Questions.forEach((question) => {
			let answer 							= member.answers.find(function (obj) { return obj.question_id == question.id});
			question.answer 				= answer.answer;
		});
		setMemberSection4(section4Questions);

	}

	const showAnswerText = (answer, questionId) => {

		let question			= questions.find(function (obj) { return obj.id == questionId; });
		let answerType 		= "green";

		if(question.answers.length == 2) {
			if(answer == 1) { answerType = "red"; }
			else if(answer == 2) { answerType = "green"; }
		} else if(question.answers.length == 3) {
			if(answer == 1) { answerType = "red"; }
			else if(answer == 2) { answerType = "orange"; }
			else if(answer == 3) { answerType = "green"; }
		} else if(question.answers.length == 4) {
			if(answer == 1) { answerType = "red"; }
			else if(answer == 2) { answerType = "red"; }
			else if(answer == 3) { answerType = "green"; }
			else if(answer == 4) { answerType = "green"; }
		} else if(question.answers.length == 5) {
			if(answer == 1) { answerType = "red"; }
			else if(answer == 2) { answerType = "red"; }
			else if(answer == 3) { answerType = "orange"; }
			else if(answer == 4) { answerType = "green"; }
			else if(answer == 5) { answerType = "green"; }
		}
		let answerVal			= question.answers.find(function (obj) { return obj.value == answer; });
		
		if(answerVal && answerVal.desktop_text) {
			if(answerType == "red") {
				return <div className="answer false">{answerVal.desktop_text.replace(/<br\/\s*\\?>/g, "\r\n")}</div>
			} else if(answerType == "orange") {
				return <div className="answer neutral">{answerVal.desktop_text.replace(/<br\/\s*\\?>/g, "\r\n")}</div>
			} else if(answerType == "green") {
				return <div className="answer true">{answerVal.desktop_text.replace(/<br\/\s*\\?>/g, "\r\n")}</div>
			}
		}
	}

	const toggleSectionDetails = (id) => {
		if(id == toggleSection) {
			setToggleSection(0);
		} else {
			setToggleSection(id);
		}
	}

	const toggleChange = (e) => {
		setToggleSection(!e.target.checked);
		if(e.target.checked) {
			toggleTeamIndividual(0);
		} else {
			toggleTeamIndividual(1)
		}
	}

	const toggleIndividualChange = (e) => {
		// setToggleSection(!e.target.checked);
		if(e.target.checked) {
			toggleTeamIndividual(0);
		} else {
			toggleTeamIndividual(1)
		}
	}

	const toggleSectionDisplay = (id) => {
		if(id == toggleSection) {
			return { display: "block" }
		} else {
			return { display: "none" }
		}
	}

	const toggleSectionIcon = (id) => {
		if(id == toggleSection) {
			return <RemoveIcon />
		} else {
			return <AddIcon />
		}
	}

	const toggleDetails = (id) => {
		if(id == toggleMore) {
			setToggleMore(0);
		} else {
			setToggleMore(id);
		}
	}

	const toggleMoreDisplay = (id) => {
		if(id == toggleMore) {
			return { display: "block" }
		} else {
			return { display: "none" }
		}
	}

	const toggleMoreIcon = (id) => {
		if(id == toggleMore) {
			return <RemoveIcon />
		} else {
			return <AddIcon />
		}
	}

	const getLeftMargin = (scoreValue) => {
    if (window.innerWidth < 800 && scoreValue > 95) {
      return { left: 90 + "%" };
    } else if (window.innerWidth < 800 && scoreValue < 5) {
      return { left: 10 + "%" };
    } else {
      return { left: scoreValue + "%" };
    }
  };

  const getBarTextLeft = (scoreValue) => {
    if (window.innerWidth < 650 && scoreValue > 80) {
      return { left: "-170px" }
    }
    return { left: "-100px" }
  }

	const showProgressBarText = (scoreValue) => {
    if (scoreValue >= 95) {
      return "Enjoying health";
    } else if (scoreValue >= 80 && scoreValue < 95) {
      return "Improving health";
    } else if (scoreValue >= 70 && scoreValue < 80) {
      return "Pursuing health";
    } else if (scoreValue < 70) {
      return "Battling unhealthiness";
    }
  }

	const sectionsAndSubScores = sections?.map((sec, idx) => ({ section: sec.section, score: subScores[idx] }));
  // remove the last Feedback section from Results and Dashboard
  sectionsAndSubScores?.pop();
  console.log("sectionsAndSubScores", sectionsAndSubScores);

	const showSchedule = () => {
    return (
			<div className="schedule-container">
				<div className="avatar">
					<img src={scheduledImage} />
				</div>
				<h3>Schedule a call with Kim</h3>
				<p>Ready to optimize your board for fundraising or craft and execute an effective annual giving or capital campaign focused on Biblical generosity?</p>
				<p>Talk with Kim to learn how your Christian school's fundraising can become truly transformative.</p>
				<div className="cta-btn">
					<a href="https://meetings.hubspot.com/kim-jennings" target="_blank">
						<Button>TALK TO KIM</Button>
					</a>
				</div>
			</div>
		);
  };

  if (isLoading) {
		return <div className="container dashboard loading"><CircularProgress style={{ color: '#F7D54D' }} size={60} /></div>
	}

	if(currentView == 1) {
		return (
			<div>
				<div className="individual-group">
					<button onClick={() => toggleTeamIndividual(1)} className={currentView == 0 ? "active left" : "left"}>TEAM</button>
					<button onClick={() => toggleTeamIndividual(0)} className={currentView == 1 ? "active right" : "right"}>INDIVIDUAL</button>
				</div>

				<div className="toggle-individual-group">
					<div className={currentView == 0 ? "active left" : "left"}>TEAM</div>
					<Switch
						checked={!toggleIndividual}
						onChange={toggleIndividualChange}
						inputProps={{ 'aria-label': 'controlled' }}
					/>
					<div className={currentView == 1 ? "active right" : "right"}>INDIVIDUAL</div>
				</div>

				<div className="container results">

					<div style={{ height: '20px' }}></div>
					<div className="selection-form">
						<div className="form-detail">
							<TextField select hiddenLabel value={selected} onChange={(e) => {updateIndividualAssessment(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text">
								{members.map((option) => (
									<MenuItem key={option.id} value={option.id}>
										{option.fullName}'s
									</MenuItem>
								))}
							</TextField>
						</div>
						<div className="form-text">assessment score</div>
					</div>

					<div className="details-container" style={{ width: "96%", margin: "0 auto" }}>

					<div className="progress-bar">
            <div className="si_ass_result">
              <div className="si_ass_progress">
                <div className="progress-line" style={{ left: individualMember?.score + '%' }}>
                  {/* <div className="progress-text" style={ windowWidth < 651 && score < 30 ? { left: '-50px', width: '10ch', top: '-85px' } : getBarTextLeft(score)}>{showProgressBarText(score)}</div> */}
                </div>
                <span className="msi_score" style={getLeftMargin(individualMember?.score)}>{individualMember?.score}%</span>
              </div>
              <div className="pervalue">
                <h4 className="nimp1" style={{ minWidth: '0%' }}></h4>
                <h4 className="nimp" style={{ minWidth: '50%' }}></h4>
                <h4 className="mexpe" style={{ minWidth: '30%' }}></h4>
                <h4 className="eexpe" style={{ minWidth: '20%' }}></h4>
                {/* <h4 className="eexpe1" style={{ minWidth: '20%' }}></h4> */}
                {/* <h4 className="eexpe2" style={{ minWidth: '18%' }}></h4> */}
                {/* <h4 className="vexpe" style={{ minWidth: '5%' }}></h4> */}
              </div>
              
              
              <div className="si_pro_header">
                <h4 className="nimp" style={{ minWidth: '0%' }}></h4>
                <h4 className="mexpe" style={{ minWidth: '50%' }}></h4>
                <h4 className="eexpe" style={{ minWidth: '30%' }}></h4>
                {/* <h4 className="eexpe1" style={{ minWidth: '30%' }}></h4> */}
                {/* <h4 className="eexpe" style={{ minWidth: '30%' }}></h4> */}
              </div>
              
              {/* <div className="pervalue-text"> */}
                {/* <h4 className="nimp" style={{ minWidth: '45%',  }}></h4> */}
                {/* <h4 className="mexpe" style={{ minWidth: '25%',  }}></h4> */}
                {/* <h4 className="eexpe" style={{ minWidth: '30%', f }}></h4> */}
                {/* <h4 className="vexpe" style={{ minWidth: '5%' }}></h4> */}
              {/* </div> */}
             
              <div className="pervalue-text">
                <p className="not-aligned" style={{ width: "50%", fontWeight: score <= 50 ? 700 : 400 }}>Suboptimal</p>
                {/* <p className="poor-aligned" style={{ marginLeft: '25%', fontWeight: score > 20 && score <= 40 ? 800 : 600 }}>Poorly<br />Aligned</p> */}
                <p className="some-aligned" style={{ width: "30%", fontWeight: score > 50 && score <= 80 ? 700 : 400 }}>Adequate</p>
                {/* <p className="well-aligned" style={{ marginLeft: '65%', fontWeight: score > 60 && score <= 80 ? 800 : 600 }}>Well<br />Aligned</p> */}
                <p className="high-aligned" style={{ width: "20%", fontWeight: score > 80 ? 700 : 400 }}>Perfect</p>
              </div>

            </div>
          </div>

					{showScoreText(individualMember?.score)}
						{/* <div className="spacer"></div>
						<div className="spacer"></div> */}

						{/* <div className="score-section-container">

							{
								sectionsAndSubScores.map((data, index) => (
									<div key={index} className="score-section">
										<p className="score-txt">{memScores[index]}%</p>
										<div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={memScores[index]} /></div>
										<p className="score-name">{ data.section }</p>
									</div>)
							)}

						</div> */}

						<h2 style={{ textAlign: "center", margin: windowWidth < 651 ? "15px auto" : "20px auto 45px", fontSize: windowWidth < 651 ? "18px" : "24px" }}>INDIVIDUAL SCORES</h2>

						{
							sectionsAndSubScores.map((data, index) => {
								
								let memberSection 		= [];
								if(index == 0) { 
									memberSection1.forEach((member) => {
										console.log(member);
										memberSection.push({id: member.id, question: member.question, answer: member.answer});
									});									
								} else if(index == 1) {
									memberSection2.forEach((member) => {
										memberSection.push({id: member.id, question: member.question, answer: member.answer});
									});
								} else if(index == 2) {
									memberSection3.forEach((member) => {
										memberSection.push({id: member.id, question: member.question, answer: member.answer});
									});
								} else if(index == 3) {
									memberSection4.forEach((member) => {
										memberSection.push({id: member.id, question: member.question, answer: member.answer});
									});
								}
								console.log(memberSection);

								return <div key={index} className="members-answered">
									<p className="section" onClick={() => toggleSectionDetails(index + 1)}>
										{data.section}
										{/* <a className="section-toggle" onClick={() => toggleSectionDetails(index + 1)}> */}
										<a className="section-toggle">
											{ toggleSectionIcon(index + 1) }
										</a>
									</p>
									<div style={ toggleSectionDisplay(index + 1) }>
										{
											memberSection.map((data, index) => (
												<div key={index} className="answered-detail"><p>{ data.question }</p> {showAnswerText(data.answer, data.id)}</div>
											)
										)}
									</div>
								</div>
							}
						)}

						<div className="hurdles-enablers">
							<h2 style={{ color: '#73B76F' }}>What about this ChMS, or which feature, do you find most helpful?</h2>
							<div className="enablers">
								<ul>
								{/* {hurdles.map((data, index) => ( */}
								{[...individualAnswer3]?.map((data, index) => (
									// data.answer != null ? <li key={index}>{ data.answer }</li> : ""
									data ? <li key={index}>{ data }</li> : ""
								))}
								</ul>
							</div>
						</div>
						
						<div className="hurdles-enablers">
							<h2 style={{ color: '#FF6645'}}>What is one feature you wish this ChMS had or offered differently?</h2>
							<div className="hurdles">
								<ul>
								{/* {enablers.map((data, index) => ( */}
								{[...individualAnswer4]?.map((data, index) => (
									// data.answer != null ? <li key={index}>{ data.answer }</li> : ""
									data ? <li key={index}>{ data }</li> : ""
								))}
								</ul>
							</div>
						</div>

						<UrgencyScore currentView={currentView} {...{ urgencyComponentProps: urgencyComponentPropsQ1Individual }} />
						<UrgencyScore currentView={currentView} {...{ urgencyComponentProps: urgencyComponentPropsQ2Individual }} />

						<div style={{ height: "40px" }}></div>
						
					</div>
					
				</div>
			</div>
		)
	} else {
		return (
			<div>

				<div className="individual-group">
					<button onClick={() => toggleTeamIndividual(1)} className={currentView == 0 ? "active left" : "left"}>TEAM</button>
					<button onClick={() => toggleTeamIndividual(0)} className={currentView == 1 ? "active right" : "right"}>INDIVIDUAL</button>
				</div>

				<div className="toggle-individual-group">
					<div className={currentView == 0 ? "active left" : "left"}>TEAM</div>
					<Switch
						checked={!toggleSection}
						onChange={toggleIndividualChange}
						inputProps={{ 'aria-label': 'controlled' }}
					/>
					<div className={currentView == 1 ? "active right" : "right"}>INDIVIDUAL</div>
				</div>
	
				<div className="container dashboard">
					<div className="details-container">
	
						{showTitleText()}

					<div className="progress-bar">
            <div className="si_ass_result">
              <div className="si_ass_progress">
                <div className="progress-line" style={{ left: score + '%' }}>
                  {/* <div className="progress-text" style={ windowWidth < 651 && score < 30 ? { left: '-50px', width: '10ch', top: '-85px' } : getBarTextLeft(score)}>{showProgressBarText(score)}</div> */}
                </div>
                <span className="msi_score" style={getLeftMargin(score)}>{score}%</span>
              </div>
              <div className="pervalue">
                <h4 className="nimp1" style={{ minWidth: '0%' }}></h4>
                <h4 className="nimp" style={{ minWidth: '50%' }}></h4>
                <h4 className="mexpe" style={{ minWidth: '30%' }}></h4>
                <h4 className="eexpe" style={{ minWidth: '20%' }}></h4>
                {/* <h4 className="eexpe1" style={{ minWidth: '20%' }}></h4> */}
                {/* <h4 className="eexpe2" style={{ minWidth: '18%' }}></h4> */}
                {/* <h4 className="vexpe" style={{ minWidth: '5%' }}></h4> */}
              </div>
              
              
              <div className="si_pro_header">
                <h4 className="nimp" style={{ minWidth: '0%' }}></h4>
                <h4 className="mexpe" style={{ minWidth: '50%' }}></h4>
                <h4 className="eexpe" style={{ minWidth: '30%' }}></h4>
                {/* <h4 className="eexpe1" style={{ minWidth: '30%' }}></h4> */}
                {/* <h4 className="eexpe" style={{ minWidth: '30%' }}></h4> */}
              </div>
              
              {/* <div className="pervalue-text"> */}
                {/* <h4 className="nimp" style={{ minWidth: '45%',  }}></h4> */}
                {/* <h4 className="mexpe" style={{ minWidth: '25%',  }}></h4> */}
                {/* <h4 className="eexpe" style={{ minWidth: '30%', f }}></h4> */}
                {/* <h4 className="vexpe" style={{ minWidth: '5%' }}></h4> */}
              {/* </div> */}
             
              <div className="pervalue-text">
                <p className="not-aligned" style={{ width: "50%", fontWeight: score <= 50 ? 700 : 400 }}>Suboptimal</p>
                {/* <p className="poor-aligned" style={{ marginLeft: '25%', fontWeight: score > 20 && score <= 40 ? 800 : 600 }}>Poorly<br />Aligned</p> */}
                <p className="some-aligned" style={{ width: "30%", fontWeight: score > 50 && score <= 80 ? 700 : 400 }}>Adequate</p>
                {/* <p className="well-aligned" style={{ marginLeft: '65%', fontWeight: score > 60 && score <= 80 ? 800 : 600 }}>Well<br />Aligned</p> */}
                <p className="high-aligned" style={{ width: "20%", fontWeight: score > 80 ? 700 : 400 }}>Perfect</p>
              </div>

            </div>
          </div>
						
						{/* <div className="spacer"></div>
						<div className="spacer"></div> */}
	
						{showScoreText(score)}
	
						{/* <div className="result-sections">
	
							{
								sectionsAndSubScores.map((data, index) => (
								<div key={index} className="score-section">									
									<p className="score-txt">{scores[index]}%</p>
									<div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={scores[index]} /></div>
									<p className="score-name">{ data.section }</p>
								</div>)
							)}

						</div> */}

					{ currentView == 0 &&	<div>
							
							<div className="mobile_scores">
								{/* <h2>INDIVIDUAL SCORES</h2> */}
								<div className="individual">

								{
									members.map((data, index) => (
										<>
											<div key={index} className="row" onClick={() => toggleDetails(data.id)}>
												<div className="number">{ index + 1 }</div>
												<div className="name">{ data.fullName }</div>
												<div className="percent">{ data.score }%</div>
												<div className="toggle">{ toggleMoreIcon(data.id) }</div>
											</div>
											<div className="scores" style={ toggleMoreDisplay(data.id) }>
												<div className="section_rows">
													<div className="section">Staff</div>
													<div className="section_score">{ data.subScore1 }%</div>
												</div>
												<div className="section_rows">
													<div className="section">Congregation</div>
													<div className="section_score">{ data.subScore2 }%</div>
												</div>
												<div className="section_rows">
													<div className="section">Personnel</div>
													<div className="section_score">{ data.subScore3 }%</div>
												</div>
												<div className="section_rows">
													<div className="section">Technology</div>
													<div className="section_score">{ data.subScore4 }%</div>
												</div>
											</div>
										</>
									)
								)}

								</div>
								
							</div> 

							<table>
								<thead>
									<tr className="header">
										<th className="hash">#</th>
										<th>Name</th>
										{
											sectionsAndSubScores.map((data, index) => (
												<th key={index} className="center-cell">{ data?.section?.toLowerCase() } (%)</th>
												)
												)}
										<th className="avg">Avg (%)</th>
									</tr>
								</thead>
								<tbody>

								{
									members.map((data, index) => (
										<tr key={index}>
											<td>{ index + 1 }</td>
											<td className="medium">{data.fullName}</td>
											{
												sectionsAndSubScores.map((section, index) => {
													let subScore;
													if(index == 0) { subScore = data.subScore1; }
													else if(index == 1) { subScore = data.subScore2; }
													else if(index == 2) { subScore = data.subScore3; }
													else if(index == 3) { subScore = data.subScore4; }
													else if(index == 4) { subScore = data.subScore5; }
													else if(index == 5) { subScore = data.subScore6; }
													else if(index == 6) { subScore = data.subScore7; }
													else if(index == 7) { subScore = data.subScore8; }
													return <td key={index} className="center-cell">{subScore}</td>
												}
												)}
											<td className="center-cell">{data.score}%</td>
										</tr>
									)
								)}
	
								<tr className='footer'>
									<th className="center-cell">&nbsp;</th>
									<th className="church-average">Team Average</th>
									{
										sectionsAndSubScores.map((data, index) => (
											<th key={index} className="center-cell">{scores[index]}</th>
											)
											)}
									<th className="center-cell">{score}%</th>
								</tr>
								</tbody>
							</table>
						</div> }
					
						<hr/>
	
						{/* <h2>Detailed Report:</h2> */}
										
						<div className="most-answered">
							<p className="green">Celebrate what you're doing well</p>
							{
								tops.map((data, index) => (
									<div key={index} className="most-answered-detail">{index + 1}. { data.question }</div>
								)
							)}
						</div>
	
						<div className="most-answered">
							<p className="red">Focus on what needs immediate attention</p>
							{
								bottoms.map((data, index) => (
									<div key={index} className="most-answered-detail">{index + 1}. { data.question }</div>
								)
							)}
						</div>
	
						<div className="alignment">
							<Tabs members={members} questions={questions} sections={sections} />
						</div>

						<div className="hurdles-enablers">
							<h2 style={{ color: '#73B76F' }}>What about this ChMS, or which feature, do you find most helpful?</h2>
							<div className="enablers">
								<ul>
								{/* {hurdles.map((data, index) => ( */}
								{teamAnswers3?.map((data, index) => (
									// data.answer != null ? <li key={index}>{ data.answer }</li> : ""
									data ? <li key={index}>{ data }</li> : ""
								))}
								</ul>
							</div>
						</div>
						
						<div className="hurdles-enablers">
							<h2 style={{ color: '#FF6645'}}>What is one feature you wish this ChMS had or offered differently?</h2>
							<div className="hurdles">
								<ul>
								{/* {enablers.map((data, index) => ( */}
								{teamAnswers4?.map((data, index) => (
									// data.answer != null ? <li key={index}>{ data.answer }</li> : ""
									data ? <li key={index}>{ data }</li> : ""
								))}
								</ul>
							</div>
						</div>
	
						<div style={{ height: windowWidth > 830 ? "40px" : "20px" }}></div>
	
					</div>

					<UrgencyScore {...{ urgencyComponentProps: urgencyComponentPropsQ1Team }} />
					<UrgencyScore {...{ urgencyComponentProps: urgencyComponentPropsQ2Team }} />
				
				</div>	

				{/* {showSchedule()} */}

				
			</div>
		);
	}
  

}
export default Dashboard;









