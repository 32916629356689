import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import ProgressGrid from './ProgressGrid';

const ProgressBarSingle = props => {

  const totalNumOfQuestions = props?.totalNumOfQuestions;

  let percentageColor = '#F7D54D';
  // let passiveColor          = '#343333';
  // let sectionstyle         = { color: activeColor, fontWeight: '700' };

  let progress = Math.round(((props?.currentProgress + 1) / totalNumOfQuestions * 100));

  return (
    <div>
      <div className="progress-bar">
        <div className="bar-section" style={{ width: "100%" }}>
          <p className="progress-percentage" style={{ width: `${progress}%`, color: percentageColor }}><span>{progress}%</span></p>
          <div className="bar"><LinearProgress className="custom-bar" variant="determinate" value={parseInt(progress)} /></div>
          {/* <ProgressGrid progress={progress} /> */}
        </div>
      </div>

    </div>
  );
}
export default ProgressBarSingle;
