import { useState, useRef, useEffect } from 'react';
import { Link, useSearchParams } from "react-router-dom";

import VideoThumbnail from '../Assets/Images/video-thumbnail.png';
import VideoThumbnailMobile from '../Assets/Images/video-thumbnail-mobile.png';
import PlayButton from '../Assets/Images/play-button.png';
import Clock from '../Assets/Images/clock.png';
import useWindowDimenions from "../Hooks/useWindowDimensions";
import '../App.scss';

const Start = props => {

  const [isLoading, setIsLoading]         = useState(true);
  const [videoThumbnailImg, setVideoThumbnailImg] = useState(VideoThumbnail);

  const { windowWidth } = useWindowDimenions();
  console.log(windowWidth);

  // useEffect(() => {
  //   if(windowWidth < 831) setVideoThumbnailImg(VideoThumbnailMobile);
  //   else setVideoThumbnailImg(VideoThumbnail);
  // }, [windowWidth])

  const [searchParams, setSearchParams]   = useSearchParams();
  const [playingVideo, setPlayingVideo]   = useState(false);
  const [startPlaying, setStartPlaying]   = useState(false);
  searchParams.get("leader");
  const vidRef = useRef(null);

  useEffect(() => {
		// document.body.style.backgroundColor = "#EEEEEE";
	},[]);

  console.log("searchParams", searchParams.get("leader"));
  console.log("searchParams", searchParams.get("user"));
  const assessmentLink = () => {
    // if(!searchParams) return "/assessment";
    // else return "/assessment?" + searchParams;
    return "/assessment?" + searchParams;
  }

  const preAssessmentLink = () => {
    if(searchParams == "") return "/before-you-start";
    else return "/before-you-start?" + searchParams;
  }

  const playVideo = (e) => {
    console.log(vidRef.current);
    vidRef.current.play();
    setPlayingVideo(true);
    if(!startPlaying) {
      setStartPlaying(true);
    }
  }

  const pauseVideo = (e) => {
    console.log(vidRef.current);
    vidRef.current.pause();
    setPlayingVideo(false);
  }

  const showThumbnail = () => {
    if(playingVideo) {
      return <img alt="thumbnail" className="thumbnail" src={videoThumbnailImg} style={{ opacity: '0'}} onClick={(e) => pauseVideo(e)} />;
    } else {
      if(startPlaying) {
        return null
      } else {
        return <>
          <img alt="thumbnail" className="thumbnail" src={videoThumbnailImg} />
          <div className="caption">
            <p className="name">Ross Miller</p>
            <p>President</p>
            <p>TouchPoint Software</p>
          </div>
        </>
      }
    }
  }

  const showJobRoles = () => {
    // return (
      // <div className='job-role-container'>
      //   <h1 className='job-role-header'>Job Roles</h1>
      //   <ol className='intro-job-roles'>
      //     <li className='into-job-role'>Job Role 1</li>
      //     <li className='into-job-role'>Job Role 2</li>
      //     <li className='into-job-role'>Job Role 3</li>
      //     <li className='into-job-role'>Job Role 4</li>
      //   </ol>
      // </div>
    // )
  }

  const showPlayButton = () => {
    if(playingVideo) {
      return null
    } else {
      return <img alt="play button" className="playbtn" src={PlayButton} onClick={(e) => playVideo(e)} />
    }
  }

  const vidsrcURL = "";

  const introFontSize = () => {
    if (searchParams.get("leader") && (windowWidth < 551)) {
      return "24px";
    } else {
      if (windowWidth < 551) return "21px";
      else if (windowWidth > 550 && windowWidth < 831) return "32px";
      else return "38px";
    }
  }

  return (
    <div className="container start">
      {/* <div className="video"> */}

        {/* <video ref={vidRef}>
          <source
            src="https://40parables-assets.s3.amazonaws.com/bleat-touchpoint.mp4"
            type="video/mp4"
            />
        </video> */}
        {/* {showThumbnail()} */}
      {/* {showPlayButton()} */}
      {showJobRoles()}
      {/* </div> */}
      <div className="intro">
         <h1 style={{ 
          margin: windowWidth < 551 ? searchParams.get("leader") ? "10px auto 40px" : "10px auto 15px" : "0 auto 10px", 
          fontSize: introFontSize(), 
        }}>Is your ChMS a good fit for your church?</h1>
        {/* <h2>
        1. Take this 3-minute ChMS test<br/>
        2. Invite your team to take it too<br/>
        3. See all results on 1 dashboard<br/>
        4. Align your team around action<br/>
        </h2> */}
        <p className='intro-para'>{searchParams.get("leader") ? "There's only one way to find out." : "See what your team thinks."}</p>
        
        {searchParams.get("leader") ? 
          <>
            <p className="member-text" style={{ margin: "5px auto" }}>And it'll only take three minutes.</p>
            {/* <li>Take this 3-minute ChMS test</li>
            <li>View your results instantly</li>
            <li>Begin to align around action with your team</li> */}
          </> : <>
          <ol className="intro-points-list">
            <li>Take this 3-minute assessment</li>
            <li>Invite your team* to take it too after you do</li> 
            <li>View everyone's results on one dashboard</li> 
            <li>Align around action as a team</li>
          </ol>
          </>}
        <div className="completion">
          <Link to={assessmentLink()} className="cta">
            ASSESS YOUR CHMS
          </Link>
          <div className='time-estimate'>
            <img className='clock-icon' src={Clock} alt="estimated time" /> <span>{searchParams.get("leader") ? "3 min" : "3 min"}</span>
          </div>
        </div>
        {!searchParams.get("leader") && <p className="start-footer">*Be sure you invite your pastors, finance managers, administrators, database administrators, and ministry leaders such as the children's ministry director, women's ministry director, etc.</p>}
      </div>
    </div>
  );
}
export default Start;