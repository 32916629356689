import { useState, useEffect } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Slider from '@mui/material/Slider';

const UrgencyScore = ({ urgencyComponentProps }, props) => {

  const [avgScore, setAvgScore] = useState(0);

  const {
    urgencyScore, 
    urgencyPercent, 
    urgencyVote1, 
    urgencyVote2, 
    urgencyVote3, 
    urgencyVote4, 
    urgencyVote5,
    headerText,
    labels,
    votes,
    team,
  } = urgencyComponentProps;

  const currentView = props.currentView;

  useEffect(() => {
    const totalParticipants = votes?.reduce((a, b) => a + b);
    // console.log("totalParticipants", totalParticipants);
    if(team && totalParticipants > 1) {
      let score = 0;
      for (let i = 0; i < votes?.length; i++) {
        let add = votes?.[i] * (i+1);
        score += add;
      }
      // console.log("avgscore", score/totalParticipants);
      setAvgScore(score/totalParticipants);
    } else {
      let idx = votes?.findIndex(elm => elm > 0);
      // console.log("idx", idx);
      setAvgScore(idx+1);
    }
  }, [team, votes])

  console.log("avgScore", avgScore); 

  const markerPercentPosition = () => {
    // if (urgencyScore-10 < 0) {
    //   return 0;
    // } else return ((urgencyScore-10)/40) * 100;

    if (avgScore <= 1) return 0;
    else { 
      // score range is 1 to 5 and there are 4 segments of 25% each. so divide by 4 
      // subtract 1 because we are starting from 1 
      return (((avgScore-1)/4) * 100);
    }
  }
  console.log("markerPercentPosition", markerPercentPosition());
  
  const avgScoreRounded = Math.round(avgScore);

  return (
    <div className="urgency">
      <h2>{headerText}</h2>
      <div className="urgency-slider" style={{ paddingTop: team ? "115px" : "70px" }}>
        {team && <h3>Participant votes</h3>}
        <Slider
          defaultValue={urgencyScore}
          valueLabelDisplay="off"
          // step={40}
          min={0}
          max={100}
          // value={25}
          value={markerPercentPosition()}
          // value={50}
          className={team ? "custom-slider" : "custom-slider individual"}
          // style={}
        />
        <div className="slider-points">

        {/* <div className="score" style={{ left: markerPercentPosition() + '%' }}>{(urgencyScore / 10).toFixed(1)}</div> */}
        <div className="score" style={{ left: markerPercentPosition() + '%' }}>{team && avgScore?.toFixed(1)}</div>
          {/* 78.5 */}
          {/* <div className="top-points" style={{ left: '0%' }}>{urgencyVote1}</div>
          <div className="top-points" style={{ left: '25%' }}>{urgencyVote2}</div>
          <div className="top-points" style={{ left: '50%' }}>{urgencyVote3}</div>
          <div className="top-points" style={{ left: '75%' }}>{urgencyVote4}</div>
          <div className="top-points" style={{ left: '100%' }}>{urgencyVote5}</div> */}
          {team && <>
          <div className="top-points" style={{ left: '0%' }}>{votes?.[0]}</div>
          <div className="top-points" style={{ left: '25%' }}>{votes?.[1]}</div>
          <div className="top-points" style={{ left: '50%' }}>{votes?.[2]}</div>
          <div className="top-points" style={{ left: '75%' }}>{votes?.[3]}</div>
          <div className="top-points" style={{ left: '100%' }}>{votes?.[4]}</div>

          <div className="top-labels" style={{ left: '0%' }}></div>
          <div className="top-labels" style={{ left: '25%' }}></div>
          <div className="top-labels" style={{ left: '50%' }}></div>
          <div className="top-labels" style={{ left: '75%' }}></div>
          <div className="top-labels" style={{ left: '100%' }}></div>
          </>
          }

          <a className="point" style={{ left: '0%' }}></a>
          <a className="point" style={{ left: '25%' }}></a>
          <a className="point" style={{ left: '50%' }}></a>
          <a className="point" style={{ left: '75%' }}></a>
          <a className="point" style={{ left: '100%' }}></a>

        </div>
        {/* <LinearProgress className="custom-bar" variant="determinate" value={avgScore} /> */}
        <LinearProgress className="custom-bar" variant="determinate" value={markerPercentPosition()} />
        <div className="slider-labels">
        <div className="label" style={{ fontWeight: avgScoreRounded == 1 ? 700 : 400 }}><p>{labels?.[0]}</p></div>
        <div className="label" style={{ left: '25%', fontWeight: avgScoreRounded == 2 ? 700 : 400 }}><p>{labels?.[1]}</p></div>
        <div className="label" style={{ left: '50%', fontWeight: avgScoreRounded == 3 ? 700 : 400 }}><p>{labels?.[2]}</p></div>
        <div className="label" style={{ left: '75%', fontWeight: avgScoreRounded == 4 ? 700 : 400 }}><p>{labels?.[3]}</p></div>
        <div className="label" style={{ left: '100%', fontWeight: avgScoreRounded == 5 ? 700 : 400 }}><p>{labels?.[4]}</p></div>
        </div>
      </div>
    </div>
  )
}

export default UrgencyScore;
