import { useState, useEffect, useContext } from 'react';
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import { Box, Stepper, Button, CircularProgress, LinearProgress } from '@mui/material';

import Question from '../Components/Questions';
import ProgressBar from '../Components/ProgressBar';
import ProgressBarSingle from '../Components/ProgressBarSingle';

import prevArrow from '../Assets/Images/prev-arrow.svg';
import nextArrow from '../Assets/Images/next-arrow.svg';
import nextArrowWhite from '../Assets/Images/next-arrow-white.svg';
import useWindowDimensions from '../Hooks/useWindowDimensions';
import '../App.scss';
// import AssessmentProgressContext from '../Store/AssessmentProgressContext';
import AssessmentQuestionContext from '../Store/AssessmentQuestionContext';


const Assessment = () => {

  // const assessProgressCtx = useContext(AssessmentProgressContext);
  // console.log("assessProgressCtx", assessProgressCtx);
  const assessCtx       = useContext(AssessmentQuestionContext);
  const { windowWidth } = useWindowDimensions();
  let navigate          = useNavigate();
  const { id }          = useParams();

  useEffect(() => {
    // document.body.style.backgroundColor = "#E5E5E5";
    
    setIsLoading(true);
    // setQs(questions);
    if(id != null) {      
      getAssessment();
    } else {
      getQuestions();
    }

    // assessProgressCtx.updateMainSectionsStatus(assessProgressCtx?.mainSections?.map(sec => {
    //   if(sec.title === "Response") return { ...sec, status: "active" };
    //   else return { ...sec, status: "unfinished" };
    // }));

  }, []);

  const [searchParams, setSearchParams]     = useSearchParams();
  const [isLoading, setIsLoading]           = useState(true);
  const [activeStep, setActiveStep]         = useState(0);
  const [prevValue, setPrevValue]           = useState(0);
  const [nextValue, setNextValue]           = useState(0);
  const [sections, setSections]             = useState([]);
  const [questions, setQuestions]           = useState([]);
  const [questionCount, setQuestionCount]   = useState(0);
  const [currentHash, setCurrentHash]       = useState(0);
  const [hasAdditional, setHasAdditional]   = useState(false);
  const [qs, setQs]                         = useState([]);
  
  // const [nextDisabled, setNextDisabled] = useState(true);

  // console.log("searchParams.get('member')", searchParams.get("member"));  

  function getAssessment() {
    fetch(process.env.REACT_APP_API_URI + '/v1/assessment/' + id + '/review', {
			method: 'GET',
			mode: 'cors',
			headers: { 'Content-Type': 'application/json' }
		}).then(response => {
			return response.json();
		}).then(data => {
			if(data.status == "success") {
        let questions = data.questions;
        if(retrieveStoredAnswers()) {
          let answeredQuestions = [];
          let storedQuestions = retrieveStoredAnswers();
          questions.forEach(q => {
            let question = storedQuestions.find(obj => obj.id == q.id);
            if(question) {
              q.answerTxt = question.answerTxt;
              q.answer = question.answer;
              q.answers = question.answers;
            }
          })
        }
				setSections(data.sections);
				setQuestions(data.questions);
        setQuestionCount(data.questions.length);
        assessCtx.addQuestions(questions);
				setHasAdditional(data.hasAdditional);
        setIsLoading(false);
        setActiveStep(data.questions.length - 1);
        setPrevValue(data.questions[data.questions.length - 1].answer);
        setCurrentHash(id);
			} else {
				// setOpenSnackbar(true);
			}
		});
  }

  function getQuestions() {

    if(assessCtx.questions.length > 0) {
      console.log("assessCtx.questions", assessCtx.questions);
      setQuestions(assessCtx.questions);
      setQuestionCount(assessCtx.questions.length);
      setIsLoading(false);

      // go to the last additional question if coming back from Details page 
      // typeof localStorage.getItem("backFromDetailsPage") is string so "true"
      if(localStorage.getItem("backFromDetailsPage") === "true") {
        setActiveStep(assessCtx.questions.length - 1);
      } else {
        // go to the first unanswered question 
        for (let i = 0; i < assessCtx.questions?.length; i++) {
          if(!assessCtx.questions?.[i]?.answer && !assessCtx.questions?.[i]?.answerTxt) {
            setActiveStep(i);
            break;
          } else setActiveStep(assessCtx.questions.length - 1);
        }
      }
    }
    else {

      let clientId              = process.env.REACT_APP_CLIENT_ID;
      fetch(process.env.REACT_APP_API_URI + '/v1/questions/' + clientId, {
        method: 'GET',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' }
      }).then(response => {
        return response.json();
      }).then(data => {
        if(data.status == "success") {
          console.log(data);
          let questions = data.questions;
          if(retrieveStoredAnswers()) {
            let answeredQuestions = [];
            let storedQuestions = retrieveStoredAnswers();
            questions.forEach(q => {
              let question = storedQuestions.find(obj => obj.id == q.id);
              if(question) {
                q.answerTxt = question.answerTxt;
                q.answer = question.answer;
                q.answers = question.answers;
              }
            })
          }
          setSections(data.sections);
          setQuestions(data.questions);
          setQuestionCount(data.questions.length);
          assessCtx.addQuestions(questions);
          setHasAdditional(data.hasAdditional);
          setIsLoading(false);
        } else {
          // setOpenSnackbar(true);
        }
      });
    }
  }

  const currentSectionName = sections?.find(section => section.id === questions[activeStep]?.sectionId)?.section;

  function completeAssessment() {

    setIsLoading(true);

    let apicall               = '/v1/assessment';
    if(searchParams.get("leader") != null) {
      apicall                 = '/v1/assessment/' + searchParams.get("leader") + '/member/' + searchParams.get("user");
    }

    let isReview              = false;
    if(id != null) {      
      isReview                = true;
    }

    let clientId              = process.env.REACT_APP_CLIENT_ID;

    // console.log("questions", questions);
		fetch(process.env.REACT_APP_API_URI + apicall, {
			method: 'POST',
			mode: 'cors',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
        clientId,
				questions,
        isReview,
        id
			})
		}).then(response => {
			return response.json();
		}).then(data => {
			if(data.status == "success") {
				if(hasAdditional) {
          if(data.member) {
            if(searchParams.get("member") === data.memberId) {
              navigate("/details/" + data.id + "?" + searchParams, { replace: true });
            } else navigate("/details/" + data.id + "?member=" + data.memberId, { replace: true });
          } else navigate("/details/" + data.id + "?" + searchParams, { replace: true });
        } else {
          if(searchParams.get("user") === data.memberId) {
            navigate("/details/" + data.id + "?member=" + data.memberId, { replace: true });
          } else navigate("/details/" + data.id + "?" + searchParams, { replace: true });
        }
			} else {
				// setOpenSnackbar(true);
			}
		});

	}

  console.log("searchParams", [...searchParams]);

  const retrieveStoredAnswers = () => {
    if (typeof window !== 'undefined') {
      // Get the current localstorage
      let savedAnswers = JSON.parse(localStorage.getItem('assessmentAnswers'));
      if(savedAnswers && savedAnswers.length != 0) {
        return savedAnswers;
      }
    }
    return;
  }

  const prevQuestion = () => {
    if(activeStep < 1) {
      // if(searchParams.get("member")) {
      if([...searchParams]?.length) {
        // navigate("/start?member=" + searchParams.get("member"), { replace: true });
        navigate("/start?" + searchParams, { replace: true });
      } else navigate("/start", { replace: true });
    } else {
      setActiveStep(activeStep - 1);
      setPrevValue(questions[activeStep - 1].answer);
    }
  };

  console.log("assessCtx.questions", assessCtx.questions);

  const nextQuestion = () => {

    // if(activeStep == questions?.length - 1) {
    //   setTimeout(() => { completeAssessment(); }, 500);
    // } else if(activeStep < questions.length - 1) {
    //   setTimeout(() => {
    //     setActiveStep(activeStep + 1);
    //     setPrevValue(questions[activeStep + 1].answer);
    //   }, 20);
    
    
      assessCtx.updateAnswer(questions?.[activeStep]?.id, questions?.[activeStep]?.answer);
      assessCtx.updateTextAnswer(questions?.[activeStep]?.id, questions?.[activeStep]?.answerTxt);
   
    if (activeStep === questions?.length - 1) {
      setTimeout(() => { completeAssessment(); }, 500);
    } else if(activeStep < questions.length - 1) {
      setTimeout(() => {
        setActiveStep(activeStep + 1);
        setPrevValue(questions[activeStep + 1].answer);
      }, 20);
      // setSection(questions[activeStep + 1].sectionId - sectionIdOffset);
    } else if(currentHash != 0 && hasAdditional) {
      // if(searchParams.get("member") != null) {
        if(searchParams?.get("member")) {
          navigate("/assessment-additional/" + currentHash + "?" + searchParams, { replace: true });
        } else {
          // navigate("/details/" + id, { replace: true });

          navigate("/details/" + currentHash + "?" + searchParams, { replace: true });
        }
    } else {
      // if(searchParams.get("member") != null) {
      if(searchParams.get("member") && currentHash) {
        navigate("/details/" + currentHash + "?" + searchParams, { replace: true });
      } else {
        console.log("\nSUBMITTING ANSWERS\n");
        setTimeout(() => { completeAssessment(); }, 500);
      }
    }
  };

  const moveToNextQuestion = (answer) => {
    questions[activeStep].answer = answer;
    assessCtx.updateAnswer(questions[activeStep].id, questions[activeStep].answer);
    assessCtx.updateTextAnswer(questions[activeStep].id, questions[activeStep].answerTxt);
    if(activeStep < questions.length - 1) {
      // setPrevValue(questions[activeStep]?.answer);
      setTimeout(() => {
        // setPrevValue(questions[activeStep]?.answer);
        setActiveStep(activeStep + 1);
      }, 500);
    } else {
      // questions[activeStep].answer = answer;
      console.log("\n\nSUBMITTING ANSWERS\n\n");
      setTimeout(() => { completeAssessment(); }, 500);
    }
  }

  // const nextDisabled = () => {
  //   if(questions[activeStep].answer > 0) {
  //     return {display: "block"};
  //   };
  //   return {display: "none"};
  // }
  console.log("questions[activeStep]", questions[activeStep]);
  const nextDisabled = (answer) => {
    if(questions[activeStep].mandatory == "no") {
      return { display: "inline-flex" };
    } else {
      if(questions[activeStep].type == "slider" && questions[activeStep].answer != 0) {
        return { display: "inline-flex" };
      } else if(questions[activeStep].type == "radio" && questions[activeStep].answer != 0) {
        return { display: "inline-flex" };
      } else if(questions[activeStep].type == "textarea" && questions[activeStep].answerTxt != "") {
        return { display: "inline-flex" };
      }
    } 
    return { display: "none" };
  }
  
  const updateTextAnswer = (id, textVal) => {
    setQuestions(questions?.map((q) => {
      if(q.id === id) q.answerTxt = textVal;
      return q;
    }))
  }

  console.log("questions", questions);

  const getQuestionsPerSection = () => {
    let arr = questions?.map(q => q.sectionId);
    let count = {};
    arr.forEach(element => {
      count[element] = (count[element] || 0) + 1;
    });
    return Object.values(count);
  }
  
  const questionsPerSection = getQuestionsPerSection();
  console.log("questionsPerSection", questionsPerSection);

  const getStepContent = (step) => {
    return (
      <Question question={questions[step]} questionCount={questionCount} questionAnswered={moveToNextQuestion} currentValue={prevValue} updateTextAnswer={updateTextAnswer} />
    );
  };

  if (isLoading) {
		return <div className="container assessment loading"><CircularProgress style={{ color: '#F7D54D' }} size={60} /></div>
	}

  return (
    <div className="container assessment">
      <Box sx={{ width: '100%' }}>
        <Stepper activeStep={activeStep}></Stepper>
      </Box>
      <div>
        <div className="steps-container">
          <div>
            <div className="steps">

              { windowWidth > 830 && <div className="spacer"></div>}

              {windowWidth > 830 ?
                <ProgressBar currentProgress={activeStep} questionsPerSection={questionsPerSection} sections={sections} count={questionCount} />
                : <>
                <ProgressBarSingle currentProgress={activeStep} totalNumOfQuestions={questionCount} />
                <p className="current-section">{currentSectionName}</p>
                </>
              }
              {/* <ProgressBarSingle currentProgress={activeStep} totalNumOfQuestions={questionCount} /> */}
              {/* <div className="spacer"></div>
              <div className="spacer"></div>
              <div className="spacer mobile-spacer"></div> */}
              {/* <div className="spacer mobile-spacer"></div> */}
              
              <div className="progress-indicator"><strong>{activeStep + 1}</strong>/{questions.length}</div>

              {getStepContent(activeStep)}

              <div className="step-buttons">
               { windowWidth > 550 && <Button onClick={prevQuestion}>
                  <img src={prevArrow} alt="previous button" />&nbsp;&nbsp;PREV
                </Button> }
               { windowWidth < 551 && <Button onClick={prevQuestion}>
                  <img src={prevArrow} alt="previous button" />
                </Button> }
                {windowWidth > 550 && <Button className="next-btn" onClick={nextQuestion} style={nextDisabled()}>
                  NEXT&nbsp;&nbsp;<img src={nextArrow} alt="next button" />
                </Button> }
                {windowWidth < 551 && <Button className="next-btn" onClick={nextQuestion} style={nextDisabled()}>
                  <img src={nextArrow} alt="next button" />
                </Button> }

                {/* { windowWidth < 551 && questions[activeStep].type == "textarea" && <Button className="next-btn" onClick={nextQuestion} >
                  <img src={nextArrow} alt="next button" />
                </Button> }  */}
              </div>
              
            </div>
          </div>
        </div>
      </div>
      
    </div>
  );
}
export default Assessment;
